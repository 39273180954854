import type { FunctionComponent, HTMLAttributes } from 'react';
import cx from 'classnames';

export const Button : FunctionComponent<HTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button
      {...props}
      className={cx('flex relative flex-center h-[40px] justify-self-end p-2 w-[105px] bg-brand-1 text-white active:bg-brand-1/25 rounded-lg text-sm', props.className)}
    >
      {props.children}
    </button>
  )
}