import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface TerminalStore {
  terminalId?: string;

  changeTerminalId(terminalId: string): void;
}

export const useTerminalStore = create(
  persist<TerminalStore>(
    (set) => ({
      terminalId: '',
      changeTerminalId: (terminalId: string) => set(() => ({ terminalId })),
    }),
    {
      name: 'terminal-store',
    },
  ),
);
