import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { type FC, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useToggle } from 'usehooks-ts';
import { InvoiceApi, ReferenceApi } from '../../api';
import { Reference } from '../../config';
import { Modal } from './Modal';
import { EnumEntity } from '../../model';
import _ from 'lodash';

interface Props {
  invoiceId: string;
  statusId: number;
  status: EnumEntity;
}

export const InvoiceStatusSelector: FC<Props> = ({ invoiceId, statusId, status }) => {
  const [newStatusId, setNewStatusId] = useState<number>();
  const [statusList, setStatusList] = useState<EnumEntity[]>([]);
  const [isOpen, toggle, setState] = useToggle();
  const queryClient = useQueryClient();

  useQuery({
    queryKey: [ReferenceApi.KEY, 'getById', Reference.InvoiceStatus, invoiceId, newStatusId],
    queryFn: () => ReferenceApi.getById(Reference.InvoiceStatus),
    onSuccess: (data) => {
      setStatusList((prev) => _.uniqBy([status, ...data], 'id'));
    },
  });

  const { mutate } = useMutation({
    mutationFn: InvoiceApi.changeStatus,
    onSuccess() {
      queryClient.refetchQueries([InvoiceApi.KEY, 'getByMerchant', invoiceId]);
      queryClient.refetchQueries([InvoiceApi.KEY, 'getEvents', invoiceId]);
      toast.success('Status changed successfully!');
    },
    onError() {
      toast.error('Failed to change status');
    },
    onSettled() {
      setState(false);
    },
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewStatusId(Number(event.target.value));
      toggle();
    },
    [toggle],
  );

  const handleConfirm = useCallback(() => {
    if (newStatusId !== undefined) {
      mutate({
        invoiceId,
        statusId: newStatusId,
      });
      setStatusList(statusList.filter(item => item.id !== status.id));
    }
  }, [mutate, invoiceId, newStatusId]);

  const oldStatusName = useMemo(
    () => statusList.find((status) => status.id === statusId)?.value,
    [statusList, statusId],
  );

  const newStatusName = useMemo(
    () => statusList.find((status) => status.id === newStatusId)?.value,
    [statusList, newStatusId],
  );

  return (
    <>
      <select className="input  px-2 py-1 rounded-lg text-xs appearance-none" onChange={handleChange} value={statusId}>
        {statusList.map((option) => (
          <option key={option.id} value={option.id}>
            {option.value}
          </option>
        ))}
      </select>
      <Modal isOpen={isOpen} onClose={toggle}>
        <div className="p-6">
          <h2 className="text-lg text-center">
            Are you sure you want to change the payment status from <strong>{oldStatusName}</strong> to{' '}
            <strong>{newStatusName}</strong>?
          </h2>
          <div className="mt-8 flex justify-center gap-4">
            <button className="button w-1/5 py-2.5" onClick={toggle} type="button">
              Cancel
            </button>
            <button className="button button--primary w-1/5 py-2.5 rounded-lg" onClick={handleConfirm} type="button">
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
